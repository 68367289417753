:root {
	color-scheme: light dark;

	--mdc-theme-login-background-image: url(~theme-img/transparent_1x1.png);
	--mdc-theme-login-background-position: center center;
	--mdc-theme-login-background-repeat: no-repeat;

	--mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
	--mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
	--mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
	--mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
	--mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
	--mdc-theme-text-primary-on-dark: #ffffff;
	--mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
	--mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
	--mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
	--mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5);

	--mdc-theme-secondary: #3884f8;
	--mdc-theme-primary: #3884f8;

	--mdc-theme-error: #ff2525;
	--mdc-theme-on-error: #fff;
	--mdc-theme-on-primary: #fff;
	--mdc-theme-on-secondary: #fff;

	// mdc-elevation--z2
	--mdc-theme-custom-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	--mdc-theme-custom-surface-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
		0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.theme-light {
	color-scheme: light;
	--mdc-theme-background: #fafafa;
	--mdc-theme-on-background: #111;
	--mdc-theme-on-surface: #111;
	--mdc-theme-surface: #fafafa;
	--mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
	--mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
	--mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
	--mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
	--mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
	--mdc-theme-custom-background-accent: rgba(0, 0, 0, 0.02);
	--mdc-theme-custom-background-light: rgba(0, 0, 0, 0.04);
	--mdc-theme-custom-background-medium: rgba(0, 0, 0, 0.2);
	--mdc-theme-custom-backdrop: rgba(250, 250, 250, 0.85);
	--mdc-theme-custom-header: rgba(250, 250, 250, 0.95);
	--mdc-theme-custom-border: rgba(0, 0, 0, 0.08);
}

.theme-dark {
	color-scheme: dark;
	--mdc-theme-background: #242424;
	--mdc-theme-on-background: #eee;
	--mdc-theme-on-surface: #eee;
	--mdc-theme-surface: #242424;
	--mdc-theme-text-hint-on-background: rgba(255, 255, 255, 0.68);
	--mdc-theme-text-icon-on-background: rgba(255, 255, 255, 0.87);
	--mdc-theme-text-disabled-on-background: rgba(255, 255, 255, 0.38);
	--mdc-theme-text-primary-on-background: rgba(255, 255, 255, 0.87);
	--mdc-theme-text-secondary-on-background: rgba(255, 255, 255, 0.54);
	--mdc-theme-custom-backdrop: rgba(36, 36, 36, 0.75);
	--mdc-theme-custom-header: rgba(36, 36, 36, 0.95);
	--mdc-theme-custom-background-accent: rgba(255, 255, 255, 0.02);
	--mdc-theme-custom-background-light: rgba(255, 255, 255, 0.04);
	--mdc-theme-custom-background-medium: rgba(255, 255, 255, 0.1);
	--mdc-theme-custom-border: rgba(255, 255, 255, 0.16);
}

body {
    font: normal 16px/18px sans-serif, arial;
	background-color: var(--mdc-theme-background);
	color: var(--mdc-theme-on-surface);
    transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;
    height: 100vh;
	width: 100vw;
	overflow: hidden;
}

html,
body {
	height: 100%;
	min-height: 100%;
	width: 100%;
	min-width: 100%;
}

* {
    box-sizing: border-box;
}

html.drag-resizing {
	body * {
		// if we user `none` then all the scrollbars auto show for
		// anything on the screen with overflow `stroke` only applies
		// to `SVG`. it gets us what we want here (given we do not
		// handle much svg and none where we show assets) and scroll 
		// bars stay hidden if the os was auto hiding them
		pointer-events: stroke !important;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none !important;
	}
	&.drag-resizing-X {
		cursor: col-resize !important;
	}
	&.drag-resizing-Y {
		cursor: row-resize !important;
	}
}

html.drag-selecting {
	body * {
		// if we user `none` then all the scrollbars auto show for
		// anything on the screen with overflow `stroke` only applies
		// to `SVG`. it gets us what we want here (given we do not
		// handle much svg and none where we show assets) and scroll 
		// bars stay hidden if the os was auto hiding them
		pointer-events: stroke !important;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none !important;
	}
	&.drag-selecting-nw, &.drag-selecting-se {
		cursor: nesw-resize !important;
	}
	&.drag-selecting-ne, &.drag-selecting-sw {
		cursor: nwse-resize !important;
	}
}

#wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    bottom: 0;
    left: 0;
    min-height: 0;
    position: absolute;
    right: 0;
    top: 0;
	overflow: hidden;
}

// NOSCRIPT
#javascript-required {
	background: var(--mdc-theme-primary);
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 100000;
	> div {
		color: var(--mdc-theme-on-primary);
		font-size: 1.25rem;
		font-weight: 900;
		height: 100%;
		padding: 16px;
		text-align: center;
		width: 100%;
		> .text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		svg {
			display: block;
			font-size: 100px;
			margin-bottom: 16px;
		}
		.help-text {
			display: block;
			font-size: 0.75rem;
			font-weight: normal;
			width: inherit;
		}
		p + .help-text {
			margin-top: 16px;
		}
	}
}