@import (inline) '@material/linear-progress/dist/mdc.linear-progress.css';

// main app loader
#nx-app-loader #loading {
	background-color: var(--mdc-theme-background);
	position: fixed;
	transition: background-color 0.3s linear;
}
#loading {
    display: flex;
    align-items: center;
    justify-content: center;
	bottom: 0;
	left: 0;
	overflow: hidden;
	pointer-events: none;
	right: 0;
	top: 0;
    z-index: 99;
    > .container {
		flex: 1 1 auto;
		min-height: 0;
		overflow: hidden;
	}
	.loader {
        align-self: stretch;
        flex: 1 1 auto;
		.ball {
			margin: 5px auto;
			&::after {
				width: 10px;
				height: 10px;
			}
		}
	}
}

#nx-app-loader {
	bottom: 0;
	font-size: 1em;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 99999;

	pointer-events: none !important;

	.progress {
		box-shadow: none;
		margin: 0;
	}

	#nx-app-message {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;

		background: rgba(200, 200, 200, 0.5);
		border-radius: 5px;
		color: var(--mdc-theme-text-primary-on-background);
		bottom: 10px;
		height: 36px;
		padding: 0 8px;
		position: fixed;
		right: 10px;
        width: 175px;
        overflow: hidden;
		// Just one more than the loading screen
        z-index: 100;
        -moz-transition: opacity 0.2s ease;
        -webkit-transition: opacity 0.2s ease;
        transition: opacity 0.2s ease;
        #nx-app-message-text {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
		&:not(.active) {
			opacity: 0;
		}
	}

	#nx-app-progress {
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		// Just one more than the loading screen
		z-index: 100;

		.mdc-linear-progress {
			border-radius: 0;
			&.mdc-linear-progress--indeterminate {
				.mdc-linear-progress__primary-bar {
                    width: 100% !important;
                    -moz-transition: none;
                    -webkit-transition: none;
					transition: none;
				}
			}
		}
		// There are some weird issues with our simulating progress and scaleX()
		.mdc-linear-progress__primary-bar {
            transform: none !important;
            -moz-transition: width 0.2s linear;
            -webkit-transition: width 0.2s linear;
			transition: width 0.2s linear;
			// Gets bogged down when our app is loading everything
			// transition: width 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
			width: 0;
		}
		&:not(.active) {
			visibility: hidden;
		}
		&.active .mdc-linear-progress__primary-bar {
			will-change: width;
		}
		&.disable-transition .mdc-linear-progress__primary-bar {
			transition: none;
		}
	}
}

.theme-dark {
	#nx-app-loader {
		#nx-app-message {
			background: var(--mdc-theme-custom-background-light);
			box-shadow: var(--mdc-theme-custom-shadow);
		}
		#nx-app-progress {
			.mdc-linear-progress {
				background-color: #524840;
			}
		}
	}
}

// general purpose loader rules:
// show .spinners inside of .loading things, hide them otherwise.
.loader {
    display: none;
    
    align-items: center;
    flex-direction: column;
    justify-content: center;
    zoom: 1;
    .container {
        display: flex;
        flex: 0 0 150px;
        flex-direction: column;
        .ball {
            display: flex;
            flex: 0 0 auto;
            margin: 2.5px auto;
			width: 10px;
			height: 10px;
			border-radius: 5px;
        }
    }
}

// TODO: make loading stuff a mixin
#loading .loader,
.loading .loader {
	display: flex;
	flex-direction: column;
	.ball {
		background: var(--mdc-theme-primary);
		&:nth-child(1), &:nth-child(7) {
            -moz-animation: loading_ball_right 1s infinite ease-in-out;
            -webkit-animation: loading_ball_right 1s infinite ease-in-out;
			animation: loading_ball_right 1s infinite ease-in-out;
		}
		&:nth-child(2) {
            -moz-animation: loading_ball_left 1.1s infinite ease-in-out;
            -webkit-animation: loading_ball_left 1.1s infinite ease-in-out;
			animation: loading_ball_left 1.1s infinite ease-in-out;
		}
		&:nth-child(3) {
            -moz-animation: loading_ball_right 1.05s infinite ease-in-out;
            -webkit-animation: loading_ball_right 1.05s infinite ease-in-out;
			animation: loading_ball_right 1.05s infinite ease-in-out;
		}
		&:nth-child(4) {
            -moz-animation: loading_ball_left 1.15s infinite ease-in-out;
            -webkit-animation: loading_ball_left 1.15s infinite ease-in-out;
			animation: loading_ball_left 1.15s infinite ease-in-out;
		}
		&:nth-child(5) {
            -moz-animation: loading_ball_right 1.1s infinite ease-in-out;
            -webkit-animation: loading_ball_right 1.1s infinite ease-in-out;
			animation: loading_ball_right 1.1s infinite ease-in-out;
		}
		&:nth-child(6) {
            -moz-animation: loading_ball_left 1.05s infinite ease-in-out;
            -webkit-animation: loading_ball_left 1.05s infinite ease-in-out;
			animation: loading_ball_left 1.05s infinite ease-in-out;
		}
	}
}

@-webkit-keyframes loading_ball_left {
	0% {
		-webkit-transform: translateX(15px);
	}
	50% {
		-webkit-transform: translateX(-15px);
	}
	100% {
		-webkit-transform: translateX(15px);
	}
}
@-moz-keyframes loading_ball_left {
	0% {
		-moz-transform: translateX(15px);
	}
	50% {
		-moz-transform: translateX(-15px);
	}
	100% {
		-moz-transform: translateX(15px);
	}
}
@keyframes loading_ball_left {
	0% {
		transform: translateX(15px);
	}
	50% {
		transform: translateX(-15px);
	}
	100% {
		transform: translateX(15px);
	}
}

@-moz-keyframes loading_ball_right {
	0% {
		-moz-transform: translateX(-15px);
	}
	50% {
		-moz-transform: translateX(15px);
	}
	100% {
		-moz-transform: translateX(-15px);
	}
}
@-webkit-keyframes loading_ball_right {
	0% {
		-webkit-transform: translateX(-15px);
	}
	50% {
		-webkit-transform: translateX(15px);
	}
	100% {
		-webkit-transform: translateX(-15px);
	}
}
@keyframes loading_ball_right {
	0% {
		transform: translateX(-15px);
	}
	50% {
		transform: translateX(15px);
	}
	100% {
		transform: translateX(-15px);
	}
}

@-webkit-keyframes assetLoad {
	from {
		-webkit-transform: translate(0, 160px);
	}
	to {
		-webkit-transform: translate(0, 0);
	}
}
@-moz-keyframes assetLoad {
	from {
		-moz-transform: translate(0, 160px);
	}
	to {
		-moz-transform: translate(0, 0);
	}
}
// loading assetListItems
@keyframes assetLoad {
	from {
		transform: translate(0, 160px);
	}
	to {
		transform: translate(0, 0);
	}
}